import styles from "./Footer.module.scss";
import Image from "next/image";
import facebookIcon from "@/res/icons/facebook.svg";
import discordIcon from "@/res/icons/discord.svg";
import redditIcon from "@/res/icons/reddit.svg";
import instagramIcon from "@/res/icons/instagram.svg";
import twitterIcon from "@/res/icons/twitter.svg";
import Link from "next/link";
import {
    DISCORD_INVITE_URL,
    FACEBOOK_PAGE_URL,
    INSTAGRAM_PAGE_URL,
    KOFI_URL,
    REDDIT_URL, TWITTER_URL,
} from "@/ts/business/constants";
import { RoyalBanner } from "@/app_components/social/banner/RoyalBanner";
import { FooterProfileLink } from "@/app_components/layout/FooterProfileLink";
import { CookiePreferencesButton } from "@/app_components/cookie/CookiePreferencesButton";


interface FooterProps {
    className?: string;
}

export default function Footer({ className = "" }: FooterProps) {
    return (
        <footer className={`${className} ${styles.footer}`}>
            <div className={styles.footer_container}>

                <div className={styles.footer_links}>

                    <div className={styles.link_column}>
                        <div className={styles.link_column_section}>
                            <div className={styles.link_column_title}>
                                The Game
                            </div>
                            <Link className={styles.link} href="/">
                                Home
                            </Link>
                            <Link className={styles.link} href="/game">
                                Play
                            </Link>
                            <Link className={styles.link} href="/rules">
                                Rules
                            </Link>
                            <FooterProfileLink className={styles.link} />
                        </div>

                        <div className={styles.link_column_section}>
                            <div className={styles.link_column_title}>
                                Policies
                            </div>
                            <Link className={styles.link} href="/privacy">
                                Privacy Policy
                            </Link>
                            <Link className={styles.link} href="/terms">
                                Terms & Conditions
                            </Link>
                            <Link className={styles.link} href="/cookies">
                                Cookie Policy
                            </Link>
                            <CookiePreferencesButton className={styles.link} />
                        </div>
                    </div>

                    <div className={styles.link_column}>
                        <div className={styles.link_column_section}>
                            <div className={styles.link_column_title}>
                                About Us
                            </div>
                            <Link className={styles.link} href="/about">
                                About
                            </Link>
                            <Link className={styles.link} href="/blog">
                                Blog
                            </Link>
                            <Link className={styles.link} href={KOFI_URL}>
                                Ko-Fi
                            </Link>
                            <Link className={styles.link} href="/contact">
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </div>

                <div className={styles.footer_right}>
                    <RoyalBanner className={styles.logo} />
                    <span className={styles.copyright}>
                        © Rosette Games Ltd, 2024
                    </span>
                    <div className={styles.footer_social_icons}>
                        <Link className={styles.social_icon} href={FACEBOOK_PAGE_URL}>
                            <Image
                                src={facebookIcon}
                                alt="Facebook"
                                width="48"
                                height="48" />
                        </Link>
                        <Link className={styles.social_icon} href={INSTAGRAM_PAGE_URL}>
                            <Image
                                src={instagramIcon}
                                alt="Instagram"
                                width="48"
                                height="48" />
                        </Link>
                        <Link className={styles.social_icon} href={DISCORD_INVITE_URL}>
                            <Image
                                src={discordIcon}
                                alt="Discord"
                                width="48"
                                height="48" />
                        </Link>
                        <Link className={styles.social_icon} href={REDDIT_URL}>
                            <Image
                                src={redditIcon}
                                alt="Reddit"
                                width="48"
                                height="48" />
                        </Link>
                        <Link className={styles.social_icon} href={TWITTER_URL}>
                            <Image
                                src={twitterIcon}
                                alt="Twitter"
                                className={styles.twitter_icon}
                                width="48"
                                height="39" />
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
}
