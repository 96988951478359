
/**
 * We use our own classnames copy because the typechecking of the
 * normal classnames is broken and I can't figure out why.
 */
export function cn(...args: any[]): string {
    let classes = "";
    for (const arg of args) {
        if (!arg)
            continue;

        const value = parseValue(arg);
        if (value) {
            classes += ` ${value}`;
        }
    }
    return classes;
}


function parseValue(arg: any): string {
    if (typeof arg === "string")
        return arg;
    if (Array.isArray(arg))
        return cn(...arg);

    throw new Error(`Unexpected value: ${arg}`);
}
