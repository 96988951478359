import React from "react";

interface CheckIconProps {
    className?: string;
}

export default function CheckIcon({ className }: CheckIconProps) {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="40"
            height="40">

            <path d="M19.502 5.166 9 15.666 4.83 11.49 2.9 13.41l6.1 6.1L21.51 7Z" />
        </svg>
    );
}
