
export class AccessibilitySettings {
    private readonly prefersReducedMotion: boolean;

    constructor(prefersReducedMotion: boolean) {
        this.prefersReducedMotion = prefersReducedMotion;
    }

    useReducedMotion(): boolean {
        return this.prefersReducedMotion;
    }
}
