"use client";
import styles from "./global-error.module.scss";
import Error from "next/error";
import { MatomoProvider } from "@/app_components/Matomo";
import { interFont } from "@/app_components/font/interFont";
import { ReactQueryProvider } from "@/app_components/ReactQueryProvider";
import { AccessibilityProvider } from "@/app_components/accessibility/AccessibilityContext";
import Header from "@/app_components/layout/Header";
import Footer from "@/app_components/layout/Footer";
import RoyalUrButton from "@/app_components/generic/buttons/RoyalUrButton";


interface GlobalErrorProps {
    error: Error & { digest?: string };
    reset: () => void;
}


export default function GlobalError({
    reset,
}: GlobalErrorProps) {
    return (
        <html lang="en">
            <MatomoProvider />
            <body className={`${interFont.className}`}>
                <ReactQueryProvider>
                    <AccessibilityProvider>
                        <Header />
                        <main className={styles.main}>
                            <section>
                                <h2>Sorry, something went wrong!</h2>
                                <div className={styles.buttons}>
                                    <RoyalUrButton style="cta" href="/">
                                        Back to Home
                                    </RoyalUrButton>
                                    <RoyalUrButton onClick={() => reset()}>
                                        Try again
                                    </RoyalUrButton>
                                </div>
                            </section>
                        </main>
                        <Footer />
                    </AccessibilityProvider>
                </ReactQueryProvider>
            </body>
        </html>
    );
}
