"use client";

import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { AccessibilitySettings } from "@/app_components/accessibility/AccessibilitySettings";


const AccessibilityContext = createContext<AccessibilitySettings | null>(null);

export function useAccessibility(): AccessibilitySettings {
    const settings = useContext(AccessibilityContext);
    if (!settings)
        throw new Error("usePrefersReducedMotion must be used within a AccessibilityProvider");

    return settings;
}

interface AccessibilityProviderProps {
    children: ReactNode;
}

export function AccessibilityProvider({ children }: AccessibilityProviderProps): React.JSX.Element {
    const reducedMotionQuery = "(prefers-reduced-motion: no-preference)";
    const [prefersReducedMotion, setPrefersReducedMotion] = useState(true);

    useEffect(() => {
        const mediaQueryList = window.matchMedia(reducedMotionQuery);
        setPrefersReducedMotion(!mediaQueryList.matches);
        const listener = (event: MediaQueryListEvent) => {
            setPrefersReducedMotion(!event.matches);
        };
        mediaQueryList.addEventListener("change", listener);
        return () => {
            mediaQueryList.removeEventListener("change", listener);
        };
    }, []);

    const settings = new AccessibilitySettings(prefersReducedMotion);
    return (
        <AccessibilityContext.Provider value={settings}>
            {children}
        </AccessibilityContext.Provider>
    );
}
